@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap);
.bold-700 {
	font-weight: 700;
}

.wrapper {
	background-color: #fff;
	border: 1px solid #fff;
	height: 3.5rem;
	width: 3.5rem;
	text-align: center;
	font-size: 40px;
	vertical-align: middle;
	border-radius: 5px;
	margin-right: 1rem;
	margin-bottom: 1rem;
	display: inline-block;
	cursor: pointer;
	position: relative;
	transition-property: background, border;
	transition-duration: 0.2s;
	transition-timing-function: linear;
}

.merged2,
.merged3 {
	width: 8rem;
}

.merged4 {
	width: 12.5rem;
}

.favWrapper {
	background-color: #f1f3f5;
	border-radius: 5px;
	padding: 1.25rem;
}

.modal-content {
	background-color: #f1f3f5 !important;
	border: none !important;
	border-radius: 0 !important;
}

.modal-header {
	border-bottom: none !important;
}

.modal-title {
	font-size: 1.75rem !important;
}

.modal-footer {
	border-top: none !important;
}

.modal-dialog {
	margin: 0 !important;
}

@media screen and (min-width: 600px) {
	.modal-dialog {
		margin: 0 auto !important;
		max-width: 720px !important;
	}
	.modal-content {
		border-radius: 0 0 0.3rem 0.3rem !important;
	}
}

.wrapper:hover {
	background-color: #f1f3f5;
	border: 1px solid #f1f3f5;
}

.favWrapper .wrapper:hover,
.modal-content .wrapper:hover {
	background-color: #e9ebed;
	border: 1px solid #e9ebed;
}

#FlexCon .item {
	display: inline-block;
}

.deleted {
	background-color: #de593c;
	position: absolute;
	bottom: -5px;
	right: -5px;
	width: 20px;
	height: 20px;
	z-index: 1100;
	display: none;
	border-radius: 10px;
}

.deleted img {
	width: 0.5rem;
	margin-top: 0;
	margin-left: 0.4rem;
}

.favWrapper .wrapper:hover .deleted,
.modal-content .wrapper:hover .deleted {
	display: flex;
}

.producthunt {
	position: fixed;
	bottom: 2rem;
	left: 2rem;
	z-index: 1055;
}

.emojiStatus {
	padding: 0.4rem 0.7rem;
	text-align: center;
	color: #133352;
	font-size: 14px;
	font-weight: 600;
	border-radius: 5px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	z-index: 1060;
}

.emojiStatusCopied {
	background-color: #64f8d6;
	position: fixed;
	bottom: 2rem;
	right: 2rem;
}

.emojiStatusRemoved {
	background-color: #ffd150;
	position: fixed;
	bottom: 5rem;
	right: 2rem;
}

.emojiStatusSvg {
	margin-top: -0.1em;
	margin-right: 0.4rem;
	font-size: 1.1em;
}

.sticky-top {
	top: 6rem !important;
}

.wrapper span {
	transition: all 0.2s ease-in-out;
	display: inline-block;
}

.wrapper:hover span {
	transform: scale(1.1);
}

.wrapper:active span {
	transform: scale(1);
	transition: all 0.1s ease-in-out;
}

footer,
footer a {
	color: #a1a1a1;
	font-size: 14px;
}

footer a:hover {
	color: #818181;
	text-decoration: none;
}

.bgBlue {
	background-color: #183153;
	color: #c3c6d1;
	border-radius: 5px;
	padding: 2em;
	font-weight: 600;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	position: relative;
}

.thankYou {
	position: absolute;
	bottom: 10px;
	right: 10px;
}

.gray {
	color: #94989c;
}

.subline {
	color: #94989c;
	margin-top: 0.5em;
	margin-left: 0.5em;
	font-size: 1rem;
}

#sortable {
	max-height: 420px;
	overflow: scroll;
}

.searchResults span {
	background-color: #ffd150;
	color: #133352;
	padding: 0.2rem 0.5rem;
	border-radius: 5px;
}

.modal-content {
	height: 100vh;
}

.wrapper span {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.buttonShare {
	text-align: left !important;
	cursor: pointer;
	position: relative;
}

.urlCopied {
	content: "✓ copied";
	position: absolute;
	font-size: 0.8rem;
	font-weight: normal;
	right: -10px;
	bottom: -15px;
	background-color: #ffd150;
	border-radius: 3px;
	padding: 0.2rem 0.4rem 0.3rem 0.4rem;
	opacity: 0;
}

.buttonShare:focus .urlCopied,
.buttonShare:active .urlCopied {
	opacity: 0;
	-webkit-animation: fade 2s linear;
	        animation: fade 2s linear;
}

@-webkit-keyframes fade {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 1;
	}
	95% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fade {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 1;
	}
	95% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.navbar-brand a,
.navbar-brand a:hover {
	color: #222;
	text-decoration: none;
}

.navbar-light .navbar-toggler-icon {
	background-image: url(/static/media/iconSearch.f3e9ae51.svg) !important;
	background-size: 18px;
}

.navbar-light .navbar-toggler {
	background-color: #64f8d6;
	border: none;
	padding: 0.25rem 0.3rem;
}

.wave {
	-webkit-animation-name: waveAnimation;
	        animation-name: waveAnimation;
	-webkit-animation-duration: 3s;
	        animation-duration: 3s;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	transform-origin: 70% 70%;
	display: inline-block;
	margin-right: 0.1em;
}

.waveNoAnimaion {
	display: inline-block;
	margin-right: 0.1em;
}

/* 
@keyframes waveAnimation {
	0% {
		transform: rotate(0deg);
	}
	5% {
		transform: rotate(-6deg);
	}
	10% {
		transform: rotate(11deg);
	}
	20% {
		transform: rotate(-11deg);
	}
	30% {
		transform: rotate(11deg);
	}
	40% {
		transform: rotate(-11deg);
	}
	50% {
		transform: rotate(11deg);
	}
	60% {
		transform: rotate(-11deg);
	}
	70% {
		transform: rotate(11deg);
	}
	80% {
		transform: rotate(-11deg);
	}
	90% {
		transform: rotate(11deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
*/

body {
	margin: 0;
	font-family: "Nunito", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f8f9fa;
	color: #222 !important;
	font-weight: 400;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 600;
}

.customNavbar {
	background-color: #fff;
}

.navbar-brand {
	font-size: 28px;
}

.customNavbar .form-control {
	background-color: #f8f9fa;
	border: none !important;
}

@media only screen and (max-width: 991px) {
	.customNavbar .form-control {
		margin: 1rem 0;
	}
}

@media only screen and (min-width: 992px) {
	.customNavbar .form-control {
		width: 400px;
		margin-left: 1rem;
		height: 52px;
	}
}

.form-control:hover,
.form-control:focus {
	box-shadow: 0 0 0 0.1rem #ffd150;
}

.btn-primary {
	background-color: #64f8d6;
	border-color: #64f8d6;
	color: #133352;
	font-weight: 600;
}

.btn-primary:hover {
	background-color: #49e5c1;
	border-color: #49e5c1;
	color: #133352;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
	background-color: #64f8d6;
	border-color: #64f8d6;
	color: #133352;
}

.btn-primary.focus,
.btn-primary:focus {
	background-color: #64f8d6;
	border-color: #64f8d6;
	color: #133352;
	box-shadow: none;
}

.modal-header .close {
	background-color: #64f8d6;
	border-color: #64f8d6;
	color: #133352;
	margin: 0;
	padding: 0.375rem 0.75rem;
	opacity: 1;
	text-shadow: none;
	border-radius: 0.25rem;
	font-weight: 600;
}

.formBody .form-control {
	background-color: #fff;
	border: none !important;
}

footer,
footer a {
	color: #a1a1a1;
	font-size: 14px;
}

footer a:hover {
	color: #818181;
	text-decoration: none;
}

