@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap");

body {
	margin: 0;
	font-family: "Nunito", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f8f9fa;
	color: #222 !important;
	font-weight: 400;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 600;
}

.customNavbar {
	background-color: #fff;
}

.navbar-brand {
	font-size: 28px;
}

.customNavbar .form-control {
	background-color: #f8f9fa;
	border: none !important;
}

@media only screen and (max-width: 991px) {
	.customNavbar .form-control {
		margin: 1rem 0;
	}
}

@media only screen and (min-width: 992px) {
	.customNavbar .form-control {
		width: 400px;
		margin-left: 1rem;
		height: 52px;
	}
}

.form-control:hover,
.form-control:focus {
	box-shadow: 0 0 0 0.1rem #ffd150;
}

.btn-primary {
	background-color: #64f8d6;
	border-color: #64f8d6;
	color: #133352;
	font-weight: 600;
}

.btn-primary:hover {
	background-color: #49e5c1;
	border-color: #49e5c1;
	color: #133352;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
	background-color: #64f8d6;
	border-color: #64f8d6;
	color: #133352;
}

.btn-primary.focus,
.btn-primary:focus {
	background-color: #64f8d6;
	border-color: #64f8d6;
	color: #133352;
	box-shadow: none;
}

.modal-header .close {
	background-color: #64f8d6;
	border-color: #64f8d6;
	color: #133352;
	margin: 0;
	padding: 0.375rem 0.75rem;
	opacity: 1;
	text-shadow: none;
	border-radius: 0.25rem;
	font-weight: 600;
}

.formBody .form-control {
	background-color: #fff;
	border: none !important;
}
