footer,
footer a {
	color: #a1a1a1;
	font-size: 14px;
}

footer a:hover {
	color: #818181;
	text-decoration: none;
}
