.buttonShare {
	text-align: left !important;
	cursor: pointer;
	position: relative;
}

.urlCopied {
	content: "✓ copied";
	position: absolute;
	font-size: 0.8rem;
	font-weight: normal;
	right: -10px;
	bottom: -15px;
	background-color: #ffd150;
	border-radius: 3px;
	padding: 0.2rem 0.4rem 0.3rem 0.4rem;
	opacity: 0;
}

.buttonShare:focus .urlCopied,
.buttonShare:active .urlCopied {
	opacity: 0;
	animation: fade 2s linear;
}

@keyframes fade {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 1;
	}
	95% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
