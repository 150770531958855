.navbar-brand a,
.navbar-brand a:hover {
	color: #222;
	text-decoration: none;
}

.navbar-light .navbar-toggler-icon {
	background-image: url(../img/iconSearch.svg) !important;
	background-size: 18px;
}

.navbar-light .navbar-toggler {
	background-color: #64f8d6;
	border: none;
	padding: 0.25rem 0.3rem;
}

.wave {
	animation-name: waveAnimation;
	animation-duration: 3s;
	animation-iteration-count: 1;
	transform-origin: 70% 70%;
	display: inline-block;
	margin-right: 0.1em;
}

.waveNoAnimaion {
	display: inline-block;
	margin-right: 0.1em;
}

/* 
@keyframes waveAnimation {
	0% {
		transform: rotate(0deg);
	}
	5% {
		transform: rotate(-6deg);
	}
	10% {
		transform: rotate(11deg);
	}
	20% {
		transform: rotate(-11deg);
	}
	30% {
		transform: rotate(11deg);
	}
	40% {
		transform: rotate(-11deg);
	}
	50% {
		transform: rotate(11deg);
	}
	60% {
		transform: rotate(-11deg);
	}
	70% {
		transform: rotate(11deg);
	}
	80% {
		transform: rotate(-11deg);
	}
	90% {
		transform: rotate(11deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
*/
