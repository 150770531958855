.bold-700 {
	font-weight: 700;
}

.wrapper {
	background-color: #fff;
	border: 1px solid #fff;
	height: 3.5rem;
	width: 3.5rem;
	text-align: center;
	font-size: 40px;
	vertical-align: middle;
	border-radius: 5px;
	margin-right: 1rem;
	margin-bottom: 1rem;
	display: inline-block;
	cursor: pointer;
	position: relative;
	transition-property: background, border;
	transition-duration: 0.2s;
	transition-timing-function: linear;
}

.merged2,
.merged3 {
	width: 8rem;
}

.merged4 {
	width: 12.5rem;
}

.favWrapper {
	background-color: #f1f3f5;
	border-radius: 5px;
	padding: 1.25rem;
}

.modal-content {
	background-color: #f1f3f5 !important;
	border: none !important;
	border-radius: 0 !important;
}

.modal-header {
	border-bottom: none !important;
}

.modal-title {
	font-size: 1.75rem !important;
}

.modal-footer {
	border-top: none !important;
}

.modal-dialog {
	margin: 0 !important;
}

@media screen and (min-width: 600px) {
	.modal-dialog {
		margin: 0 auto !important;
		max-width: 720px !important;
	}
	.modal-content {
		border-radius: 0 0 0.3rem 0.3rem !important;
	}
}

.wrapper:hover {
	background-color: #f1f3f5;
	border: 1px solid #f1f3f5;
}

.favWrapper .wrapper:hover,
.modal-content .wrapper:hover {
	background-color: #e9ebed;
	border: 1px solid #e9ebed;
}

#FlexCon .item {
	display: inline-block;
}

.deleted {
	background-color: #de593c;
	position: absolute;
	bottom: -5px;
	right: -5px;
	width: 20px;
	height: 20px;
	z-index: 1100;
	display: none;
	border-radius: 10px;
}

.deleted img {
	width: 0.5rem;
	margin-top: 0;
	margin-left: 0.4rem;
}

.favWrapper .wrapper:hover .deleted,
.modal-content .wrapper:hover .deleted {
	display: flex;
}

.producthunt {
	position: fixed;
	bottom: 2rem;
	left: 2rem;
	z-index: 1055;
}

.emojiStatus {
	padding: 0.4rem 0.7rem;
	text-align: center;
	color: #133352;
	font-size: 14px;
	font-weight: 600;
	border-radius: 5px;
	width: fit-content;
	z-index: 1060;
}

.emojiStatusCopied {
	background-color: #64f8d6;
	position: fixed;
	bottom: 2rem;
	right: 2rem;
}

.emojiStatusRemoved {
	background-color: #ffd150;
	position: fixed;
	bottom: 5rem;
	right: 2rem;
}

.emojiStatusSvg {
	margin-top: -0.1em;
	margin-right: 0.4rem;
	font-size: 1.1em;
}

.sticky-top {
	top: 6rem !important;
}

.wrapper span {
	transition: all 0.2s ease-in-out;
	display: inline-block;
}

.wrapper:hover span {
	transform: scale(1.1);
}

.wrapper:active span {
	transform: scale(1);
	transition: all 0.1s ease-in-out;
}

footer,
footer a {
	color: #a1a1a1;
	font-size: 14px;
}

footer a:hover {
	color: #818181;
	text-decoration: none;
}

.bgBlue {
	background-color: #183153;
	color: #c3c6d1;
	border-radius: 5px;
	padding: 2em;
	font-weight: 600;
	width: fit-content;
	position: relative;
}

.thankYou {
	position: absolute;
	bottom: 10px;
	right: 10px;
}

.gray {
	color: #94989c;
}

.subline {
	color: #94989c;
	margin-top: 0.5em;
	margin-left: 0.5em;
	font-size: 1rem;
}

#sortable {
	max-height: 420px;
	overflow: scroll;
}

.searchResults span {
	background-color: #ffd150;
	color: #133352;
	padding: 0.2rem 0.5rem;
	border-radius: 5px;
}

.modal-content {
	height: 100vh;
}

.wrapper span {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}